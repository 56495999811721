<template>
  <div
    class="header"
    ref="header"
  >
    <div class="header-c">
      <div class="container">
        <div class="l">
          <router-link
            to="/"
            class="logo"
          >
            <img src="/static/img/logo.png">
            <!-- <div class="title">
              <p>甘肃文锐电子交易网络有限公司</p>
              <p class="en">GANSU VERYTRADING NETWORK CO., LTD</p>
            </div> -->
          </router-link>
        </div>
        <div class="r">
          <div class="nav-box">
            <v-mainNavBar />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vMainNavBar from '@/views/main-navbar.vue'
export default {
  components: {
    vMainNavBar
  },
  data () {
    return {
      scrollY: 0,
      startScrollY: 0
    }
  },
  methods: {
    /**
     * 获取界面滚动位置
     */
    getScrollY () {
      this.scrollY =
        document.documentElement.scrollTop || document.body.scrollTop
      // 设置行内样式
      if (this.scrollY > this.startScrollY) {
        this.$refs.header.classList.add('header-fixed')
      } else {
        this.$refs.header.classList.remove('header-fixed')
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.getScrollY)
    this.startScrollY = this.$refs.header.offsetHeight - 10
  }
}
</script>

<style lang="less" scoped>
.header {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  box-sizing: border-box;
  width: @mxnWith100vw;
  min-width: @minWith;
  background: #ffffff3a;
  border-bottom: 1px solid #e2e0e1;
  box-shadow: @gBoxShadowBlack;
  z-index: 10000;
  &.header-fixed {
    position: fixed;
    width: @mxnWith100vw;
    min-width: unset;
    animation: show 0.3s;
    // 从上往下显示动画
    @keyframes show {
      0% {
        opacity: 0;
        transform: translate(-50%, -100%);
      }
      100% {
        opacity: 1;
        transform: translate(-50%, 0);
      }
    }
    // 媒体查询, 主要用于子组件
    @media (max-width: 1100px) {
      /deep/ .navbar-list {
        & + .navbar-list {
          margin-left: 10px;
        }
        p {
          font-size: 16px;
        }
      }
    }
    @media (max-width: 800px) {
      /deep/ .navbar-list {
        p {
          font-size: 14px;
        }
      }
    }
    @media (max-width: 720px) {
      /deep/ .nav-box {
        min-width: 400px;
        .navbar-list {
          p {
            font-size: 12px;
            font-weight: bold;
          }
        }
      }
    }
  }
  &-c {
    padding: 0 20px;
    box-sizing: border-box;
    background: #faf8f8;
    .container {
      margin: 0 auto;
      max-width: @mxnWith;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .l {
      align-self: center;
      .logo {
        display: flex;
        align-items: center;
        // TODO: 预留logo和字体的位置
        img {
          // width: 212px;
          height: 45px;
          // height: 35px;
          // width: 25px;
          // margin-right: 10px;
        }
        // .title {
        //   font-size: 18px;
        //   font-family: YouSheBiaoTiHei;
        //   color: #1A1311;
        //   text-shadow: 0px 1px 1px rgba(0,0,0,0.16);
        //   .en{
        //     font-size: 12px;
        //   }
        // }
      }
    }
    .r {
      display: flex;
      align-items: center;
      .nav-box {
        // 用于头部悬浮时, 样式切换
        display: flex;
        align-items: center;
        .navbar {
          // 设置组件中的样式
          padding: 0;
        }
      }
    }
  }
  &-f {
    // 未启用
    display: none;
  }
}
</style>
