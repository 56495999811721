<template>
  <div id="main">
    <main-content />
  </div>
</template>

<script>
import MainContent from './main-content'
export default {
  components: {
    MainContent
  }
}
</script>
<style lang="less" scoped>
#main{
  margin:  0 auto;
  max-width: 2400px;
  min-width: @minWith;
  height: 100%;
}
</style>
