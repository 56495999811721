<template>
  <!-- 底部 -->
  <div class="floor">
    <div class="floor-main">
      <div class="floor-t">
        <div class="nav">
          <div
            class="nav-item"
            v-for="(item, index) in navList"
            :key="index"
            @click="toRouter(item)"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="log">
          <img src="/static/img/logo-w.png">
        </div>
      </div>
      <div class="line" />
      <div class="floor-c">
        <div class="contact-information">
          <p>兰州：甘肃省兰州市城关区雁北路海鸿国际中心10楼1007-8室</p>
          <p>成都：</p>
          <p>宁波：</p>
          <p>服务热线：0931-4267890（多线客服）</p>
          <p>在线客服：7*12小时在线技术咨询（节假日除外）</p>
        </div>
        <div class="QRCode">
          <div
            class="QRCode-item"
            v-for="(item, index) in QRCodeList"
            :key="index"
          >
            <img :src="item.img">
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>
      <div class="floor-f">
        <div class="content">
          <div><p>Copyright © 2020 - All Rights Reserved</p></div>
          <div><p>甘肃文锐电子交易网络有限公司</p></div>
          <div>
            <img src="/static/img/floor/gongan.png">
            <p>
              甘公网安备62010202002060号 <a
                href="https://beian.miit.gov.cn/"
                target="_blank"
              >陇ICP备16003173号-1</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      navList: [
        {
          title: '公司信息',
          pathName: '关于我们'
        },
        {
          title: '企业文化',
          pathName: '关于我们'
        },
        {
          title: '产品案例',
          pathName: '产品中心'
        },
        {
          title: '关于我们',
          pathName: '关于我们'
        },
        {
          title: '加入我们',
          pathName: '联系我们'
        }
      ],
      QRCodeList: [
        {
          img: '/static/img/weibo.png',
          title: '点击关注官方微博'
        },
        {
          img: '/static/img/wQRCode.png',
          title: '扫码微信联系'
        },
        {
          img: '/static/img/gzhQRCode.jpg',
          title: '扫码关注微信公众号'
        }
      ]
    }
  },
  methods: {
    toRouter (item) {
      window.scrollTo({ top: 0 })
      this.$router.push({ name: item.pathName })
    }
  }
}
</script>

<style lang="less" scoped>
.floor {
  position: relative;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  min-width: @minWith;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  height: @footerHeight;
  background: #0b294d;
  font-size: 16px;
  color: #fff;
  &-main {
    position: relative;
    height: 100%;
    width: @minWith;
    margin: 0 auto;
  }
  &-t {
    padding: 40px 0 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nav {
      display: flex;
      .nav-item {
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 80px;
        }
      }
    }
    .log{
      position: relative;
      top: -10px;
    }
  }

  .line {
    width: @mxnWith100vw;
    min-width: @minWith;
    border-bottom: 1px solid #535353;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  &-c {
    padding-top: 70px;
    display: flex;
    justify-content: space-between;
    .contact-information {
      font-size: 18px;
      font-weight: 300;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    .QRCode {
      width: 520px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          height: 137px;
          width: 137px;
          margin-bottom: 9px;
        }
        p {
          font-size: 18px;
          font-weight: 300;
          color: #ffffff;
        }
      }
    }
  }
  &-f {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 10px;
    box-sizing: border-box;
    background: #0f203e;
    width: @mxnWith100vw;
    min-width: @minWith;
    .content {
      width: @minWith;
      display: flex;
      justify-content: space-between;
      line-height: 60px;
      margin: 0 auto;
      div {
        display: flex;
        align-items: center;

        img {
          height: 25px;
          width: 25px;
          // 中线对齐
          margin-right: 4px;
        }
      }
      a {
        color: #fff;
      }
    }
  }
  // 底部背景铺垫, 防止分辨率过大导致无法铺满
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: @mxnWith100vw;
    min-width: @minWith;
    background: #0b294d;
  }
}
</style>
