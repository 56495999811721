<template>
  <div class="main-content">
    <v-mainHeader />
    <div class="main-empty" />
    <!-- <transition name="slide-fade"> -->
    <router-view class="router-view" />
    <!-- </transition> -->
    <v-mainFloor />
  </div>
</template>

<script>
import vMainHeader from '@/views/main-header.vue'
import vMainFloor from '@/views/main-floor.vue'
export default {
  components: {
    vMainHeader,
    vMainFloor
  },
  data () {
    return {
      // 防止样式错乱, 每次切换时隐藏
      routerSwitch: true
    }
  },
  watch: {
    // 使用watch 监听$router的变化
    $route (to, from) {
      this.routerChange()
    }
  },
  methods: {
    // 切换路由时隐藏main-floor
    routerChange () {
      if (!this.routerSwitch) return
      this.routerSwitch = false
      setTimeout(() => {
        this.routerSwitch = true
      }, 300)
    }
  }
}
</script>

<style lang="less" scoped>
.main-content {
  min-width: @minWith;
  margin: 0 auto;
  position: relative;
  height: 100%;
  .router-view{
    min-height: 100vh;
    width: @minWith;
    margin: 0 auto;
    padding: 0 10px 80px;
    box-sizing: border-box;
  }
}

.main-empty{
  // 主要适用于头部信息栏留白, 便于做头部固定.
  height: @headerHeigth;
  min-width:@minWith ;
}

// 底部隐藏
.slide-fade-enter-active {
  transition: all 0.3s ease;
  transition-delay: 0.1s;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-5px);
  opacity: 0;
}
</style>
