<template>
  <div class="navbar">
    <div
      class="navbar-list"
      :class="{ on: $store.state.routerId == item.id }"
      v-for="(item, index) in navBar"
      :key="index"
      @click="toggleNavBar(item)"
      @mouseenter="mouseEn(item)"
      @mouseleave="mouseLe(item)"
    >
      <p>{{ item.pathName }}</p>
      <div
        class="wrap"
        v-show="item.id == navID"
      >
        <div class="submenu">
          <div
            class="submenu-list"
            v-for="submenu in item.children"
            :key="submenu.id"
            @click.stop="toggleNavBar(submenu)"
          >
            <div class="topside">
              <img
                :src="submenu.icon"
                :alt="submenu.pathName"
                class="icon"
              >
              <span class="title">{{ submenu.pathName }}</span>
            </div>
            <span class="describe">
              {{ submenu.describe }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      navID: -1,
      navBar: [
        {
          pathName: '首页',
          path: ['/'],
          id: 0
        },
        {
          pathName: '解决方案',
          path: ['theSolution'],
          id: 10,
          children: [
            {
              pathName: '企业招标采购综合服务解决方案',
              icon: '/static/img/navbar/ts-1.png',
              describe:
                '招标采购活动全流程电子化服务，助力企业建立现代化的供应链体系。',
              id: 11
            },
            {
              pathName: '数字农业',
              icon: '/static/img/navbar/ts-2.png',
              describe:
                '数字农业产品以交易为核心，帮助农业生产经营主体增加效益。',
              id: 12
            },
            {
              pathName: '公共资源交易',
              icon: '/static/img/navbar/ts-3.png',
              describe:
                '帮助政府提升公共资源配置的效率和效益，推动从有形市场向电子化平台转变。',
              id: 13
            }
          ]
        },
        {
          pathName: '产品中心',
          path: [],
          id: 20,
          children: [
            {
              pathName: '交易服务系统',
              icon: '/static/img/navbar/pc-1.png',
              describe:
                '招投标过程电子化、规范化平台，支持各大流程节点下子流程管理',
              id: 21
            },
            {
              pathName: '公共资源交易电子档案系统',
              icon: '/static/img/navbar/pc-2.png',
              describe: '可全量记录交易过程中的所有交易数据及交易主体行为数据',
              id: 22
            },
            {
              pathName: '公共资源交易智能见证系统',
              icon: '/static/img/navbar/pc-3.png',
              describe:
                '独立系统运行，保存项目数据，资料生成PDF并生成对应的HASH码',
              id: 23
            }
          ]
        },
        {
          pathName: '服务与支持',
          path: [],
          id: 30
        },
        {
          pathName: '新闻中心',
          path: [],
          id: 40
        },
        {
          pathName: '联系我们',
          path: [],
          id: 50
        },
        {
          pathName: '关于我们',
          path: [],
          id: 60
        }
      ]
    }
  },
  watch: {
    $route: 'initNavId'
  },
  methods: {
    /**
     * 切换导航栏
     */
    toggleNavBar (item) {
      if (item.children) return
      this.$router.push({ name: item.pathName })
    },
    // 导航鼠标移入
    mouseEn (item) {
      if (item.children != null && item.children.length > 0) {
        this.navID = item.id
      }
    },
    // 导航鼠标移出
    mouseLe (item) {
      this.navID = -1
    },
    initNavId () {
      // 路由改变 初始化导航选中状态
      this.navID = -1
    }

  }
}
</script>

<style lang="less" scoped>
.navbar {
  flex: 1;
  display: flex;
  align-content: center;
  padding: 0 80px;
  box-sizing: border-box;
  &-list {
    cursor: pointer;
    position: relative;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.1s;
    p {
      height: @headerHeigth;
      line-height: @headerHeigth;
      font-size: 16px;
      text-align: center;
      color: #333;
      transition: @gTransition;
      // 禁止换行
      white-space: nowrap;
    }
    //  选中状态
    &:after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 2px;
      background: @mainColor;
      opacity: 0;
      transition: @gTransition;
    }
    &.on,
    &:hover {
      color: #000;
      &:after {
        opacity: 1;
        width: calc(100% + 10px);
      }
    }
    & + .navbar-list {
      margin-left: 60px;
    }
    .wrap {
      padding-top: 10px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      animation: FadeIn 0.4s ease-in-out;
      .submenu {
        background: #ffffff;
        box-shadow: 0px 4px 19px 1px rgba(0, 0, 0, 0.1);
        border-radius: 2px 2px 2px 2px;
        &-list {
          padding: 18px 27px 16px;
          .topside {
            display: flex;
            align-items: center;
            .icon {
              width: 26px;
              height: 26px;
            }
            .title {
              font-size: 16px;
              color: #333333;
              line-height: 21px;
              margin-left: 13px;
              white-space: nowrap;
            }
          }
          .describe {
            display: inline-block;
            font-size: 12px;
            color: #acb9ca;
            line-height: 16px;
            margin-top: 4px;
            white-space: nowrap;
          }
          &:hover {
            background: #f9f9f9;
          }
        }
      }
    }
  }
}
@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
